<template>
  <b-card-code
    no-body
    title="List Tiket Undian"
  >
    <div class="dt_adv_search ml-1 mr-1 mb-1">
      <b-row>
        <b-col
          cols="12"
        >
          <div class="d-flex justify-content-start">
            <month-picker-input
              :key="monthPickerKey"
              class="mr-1"
              :max-date="max"
              :min-date="min"
              :lang="'id'"
              :default-month="filter.month"
              :default-year="filter.year"
              @change="getFilter"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="clearFilter"
            >
              Reset
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Pengguna</th>
            <th>Nomor Invoice</th>
            <th>Waktu</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="8"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
          >
            <td>
              <router-link :to="`/user-detail/${item.user.uuid}`">
                {{ item.user.name }}
              </router-link>
            </td>
            <td>
              {{ item.order_number }}
            </td>
            <td>{{ item.time }}</td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="8"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Ripple from 'vue-ripple-directive'

import {
  BButton,
  BRow,
  BCol,
  BSpinner,
} from 'bootstrap-vue'

export default {
  title() {
    return 'List Tiket Undian'
  },
  components: {
    BButton,
    BRow,
    BCol,
    MonthPickerInput,
    BCardCode,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    // Set maksimal date dan minimal date
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    // Bulan saat ini
    const currentMonth = now.getMonth() + 1
    const currentYear = now.getFullYear()
    // 1 Tahun Kebelakang
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth() - 11)
    minDate.setYear(minDate.getFullYear() - 1)
    // 1  Tahun Kedepan
    const maxDate = new Date(today)
    maxDate.setYear(maxDate.getFullYear() + 1)
    maxDate.setMonth(maxDate.getMonth() + 1)

    return {
      result: {},
      isLoading: false,
      filter: {
        year: currentYear,
        month: currentMonth,
      },
      defaultMonth: currentMonth,
      defaultYear: currentYear,
      min: minDate,
      max: maxDate,
      monthPickerKey: 0,
    }
  },
  watch: {
    filter: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    clearFilter() {
      // Force reload component
      this.monthPickerKey += 1

      this.filter = {
        year: this.defaultYear,
        month: this.defaultMonth,
      }
    },
    getFilter(date) {
      if (date.year && date.monthIndex) {
        this.filter = {
          year: date.year,
          month: date.monthIndex,
        }
      }
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/lottery-tickets', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style>
.month-picker-input-container {
  width: unset !important;
}
.month-picker-input {
  height: 2.142rem;
  font-size: 0.857rem;
  line-height: 1;
  padding: 0.188rem 0.857rem;
  border-radius: 0.357rem;
}
</style>
